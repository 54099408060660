module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"trackingIDhere","head":true,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"noopener"}},"gatsby-remark-relative-images","gatsby-remark-unwrap-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":824,"linkImagesToOriginal":false,"withWebp":true,"quality":75,"showCaptions":true}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
